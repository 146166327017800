import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

const Hero = () => {
  return (
    <section className="home-hero">
      <div className="container-fluid">
        <h1>
          Projekt zakończony.
          <br />
          Dziękujemy za zaufanie.
        </h1>
        <p>
          Nadal jesteśmy do Państwa dyspozycji.
          <br />
          Pozostańmy w kontakcie.
        </p>
        <div className="home-hero__cta">
          <Link
            to="/kontakt/"
            className="button button--circle button--circle-white"
          >
            kontakt
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Hero
