import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

const Invest = () => {
  return (
    <section className="home-invest">
      <div
        className="home-invest__wrapper"
        style={{
          backgroundImage: `url('${
            require("assets/images/home/home-invest-bg.jpg").default
          }')`,
        }}
      >
        <div className="container-fluid">
          <span className="home-invest__sub">Inwestuj</span>
          <h2>Jak inwestować?</h2>
        </div>

        <div className="container-fluid">
          <h3>Sprawdź jak łatwo zainwestować w kilku prostych krokach</h3>
          <Link
            to="/jak-to-dziala/"
            className="button button--circle button--circle-white"
          >
            więcej
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Invest
