import "./styles.scss"

import React from "react"

import Button from "components/Button"

const About = () => {
  return (
    <section className="home-about">
      <div className="container-fluid">
        <span className="home-about__sub">O Trust</span>
        <h2>Trust Investment Projekt 15 sp. z o.o. </h2>
        <div className="row">
          <div className="col-lg-5 col-md-6">
            <img
              src={require("assets/images/home/home-about-image.png").default}
              alt=""
            />
          </div>
          <div className="col-md-6 offset-lg-1">
            <h4>
              Trust Investment Projekt 15 to spółka z ograniczoną
              odpowiedzialnością należąca do Grupy Trust –uznanego, kieleckiego
              dewelopera, który realizuje projekty deweloperskie w sektorze
              nieruchomości mieszkaniowych i komercyjnych na terenie całej
              Polski.
            </h4>
            <p>
              Wychodząc naprzeciw rosnącemu zainteresowaniu Klientów
              współtworzeniem naszych inwestycji, a także poszukując optymalnych
              form finansowania realizacji projektu deweloperskiego
              przygotowaliśmy rozwiązanie pozwalające z jednej strony
              bezpiecznie ulokować naszym Klientom środki poprzez pożyczkę
              inwestycyjną w spółce deweloperskiej Trust Investment Projekt 15,
              a z drugiej umożliwić realizację ambitnego projektu
              deweloperskiego, inwestycji Rezydencja Sikorskiego.
            </p>
            <Button to="/o-trust/">zobacz</Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
