import "./styles.scss"

import React from "react"

import Button from "components/Button"
import NewsTile from "components/NewsTile"

import { usePosts } from "context/posts"

const News = () => {
  const { allPosts } = usePosts()

  return (
    allPosts?.length > 0 && (
      <section className="home-news">
        <div className="container-fluid">
          <div className="home-news__row">
            <h2>Aktualności</h2>
            <Button to="/aktualnosci/" className="button--bordered">
              zobacz wszystkie
            </Button>
          </div>
          <div className="row">
            {allPosts?.slice(0, 2).map((item, index) => (
              <div className="col-md-6" key={index}>
                <NewsTile item={item} />
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  )
}

export default News
